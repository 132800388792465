
.contntDiv1{
    padding-inline: 30px;
    padding-bottom: 60px;
}
.termHead1{
    text-align: center;
    padding-top: 20px;
}

.termHead2{
    padding-top: 35px;
}
.link1{
    color: green !important;
}
.subHead1{
    color: green;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
}
.subHead2{
    color: green;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
}
.lblgrp1{
    display: flex;
}
.linkalgn1{
    display: contents;
    width: 100%;
}
.lblcmn{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px
}