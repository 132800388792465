.cnclBtn {
  width: 100px;
  float: right;
  border-color: #0077b6 !important;
  color: #0077b6;
  font-weight: 700;
  background-color: white !important;
}
.cnclBtn:hover {
  width: 100px;
  float: right;
  border-color: #0077b6 !important;
  color: #0077b6;
  background-color: white !important;
  box-shadow: 0 8px 9px -2px #0077b6;
}

.forgotpagesubbtn {
  width: 100px;
  color: white;
  font-weight: 700;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  float: left;
}
.forgotpagesubbtn:hover {
  width: 100px;
  color: #0077b6;
  font-weight: 700;
  background-color: white !important;
  border-color: #0077b6 !important;
  float: left;
  box-shadow: 0 8px 9px -2px #0077b6;
}
.forgotpageresetPwdbtn {
  /* width: 100px; */
  color: white;
  font-weight: 700;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  float: left;
}
.forgotpageresetPwdbtn:hover {
  /* width: 100px; */
  color: white;
  font-weight: 700;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  float: left;
  box-shadow: 0 8px 9px -2px #0077b6;
}
.forgotContainer {
  width: 65vh;

  background-color: #fff;
  margin-top: 10%;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  /* border: 1px solid #cc2026; */
  /* padding: 3%; */
}
@media (max-width: 580px) and (min-width: 430px) {
  .forgotContainer {
    width: 50vh;
  }
}
@media (max-width: 429px) and (min-width: 318px) {
  .forgotContainer {
    width: 50vh;
  }
}

.forgotcardhead {
  font-weight: 500 !important;
  margin-top: 0px;
  text-align: center;
  font-size: xx-large;
  background-color: #0077b6;
  margin-left: -16px;
  margin-right: -15px;
}
.forgotDiv {
  justify-content: center;
  align-items: center;
  /* width: 50%;
    height: 50%; */
  /* border: 1px solid #34a69b; */
  padding-top: 1%;
  text-align: center;
}
.forgotpassHead {
  font-weight: 600;
  padding-top: 18px;
  padding-bottom: 18px;
  color: white;
  /* margin-left: 41px; */
  font-size: 20px;
  /* text-align: left; */
}
.frgtpanaccounticon {
  font-size: 30px;
}

.frgtpanaccount {
  font-size: 15px;
  margin-bottom: 20px;
  display: flex;
  margin-top: 15px;
  color: black !important;
}
.frgtpanaccount:hover {
  text-decoration: none !important;
  color: black !important;
}
img.forgotimg {
  width: 75px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  margin-top: 20px;
}
.frgterrStyle {
  color: red;
  text-align: left;
}

.backtologin {
  margin-top: 10px;
}
.emailerrmsg {
  font-size: 12px;
  margin-top: -9px;
  margin-left: 55px;
  color: red;
}
.resetforgot {
  display: flex;
  font-size: 11px;
  justify-content: right;
  color: #347329;
  margin-right: -33px;
  font-weight: 500 !important;
  cursor: pointer;
}
